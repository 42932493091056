import { get } from '@/scripts/http';

interface SubjectParam {
  "stage": number,
}

// interface User {
//   "userId": string,
//   "telephone": string,
//   "token": string
// }

export default {
  ServiceURL: "/zone/subject/api/resource",
  //登录信息验证
  async GetSubject(data: SubjectParam) {
    const res = await get(`${this.ServiceURL}/subjects`, data);
    return res;
  }
}
